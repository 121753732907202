<template>
    <div class="page">
        <app-header :navIndex="1"></app-header>
        <banner :data="banner"></banner>
        <!--关于ASC-->
        <section class="section-container d-flex flex-column flex-md-row justify-space-between space-between mt-md-n12">
            <div class="col-md-4 pa-0">
                <v-img :src="about.img"></v-img>
            </div>
            <div class="top-box col-md-7 d-flex justify-center flex-column">
                <div class="top-border hidden-md-and-up white"></div>
                <div class="about-title hidden-sm-and-down">{{about.enTitle}}</div>
                <section-title class="hidden-md-and-up" :title="about.title" :enTitle="about.enTitle"></section-title>
                <div class="desc pa-5 pa-md-0 mt-md-n10">{{about.desc}}</div>
            </div>
        </section>
        <!--公司发展-->
        <section class="d-flex flex-column">
            <div class="section-container">
                <section-title :title="development.title" :enTitle="development.enTitle"></section-title>
                <div class="desc pa-5 pa-md-0 hidden-sm-and-down">{{development.desc}}</div>
            </div>
            <div class="item-box mt-5 mt-md-12">
                <v-img :src="development.img"></v-img>
                <div class="top-box hidden-md-and-up mb-10">
                    <div class="top-border white"></div>
                    <div class="desc pa-5">{{development.desc}}</div>
                </div>
                <div class="item d-flex flex-column flex-md-row justify-center">
                    <div class="inner pa-5 pa-md-12" v-for="(item,index) in development.items"
                         :class="'item'+index"
                         :key="index">
                        <div class="text-center mb-3 mb-md-8 white--text item-title">{{item.title}}</div>
                        <div class="desc white--text">{{item.desc}}</div>
                    </div>
                </div>
            </div>
        </section>

        <!--葡萄酒服务和知识推广-->
        <section class="mb-10 mb-md-12">
            <section-title :title="service.title" :enTitle="service.enTitle"></section-title>
            <div class="service-box">
                <div class="inner-box grey lighten-3 hidden-sm-and-down">
                </div>
                <div class="inner d-flex flex-column flex-md-row justify-space-between mt-md-12">
                    <div class="desc pa-5 hidden-md-and-up" v-html="service.desc"></div>
                    <div class="service-img col-md-4 pa-0">
                        <v-img contain :src="service.img"></v-img>
                    </div>
                    <div class="desc col-md-7 mt-12 mr-2 pr-12 hidden-sm-and-down" v-html="service.desc"></div>
                </div>
            </div>
        </section>

        <!--公司产品-->
        <!--<section class="section-container">
            <section-title :title="product.title" :enTitle="product.enTitle"></section-title>
            <div class="desc pa-5 pa-md-0" v-html="product.desc"></div>
        </section>-->

    </div>
</template>

<script>
    import SectionTitle from "./components/section-title";
    import Banner from "../../components/base/banner";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            Banner,
            SectionTitle
        },
        data() {
            return {
                banner: {
                    imgUrl: 'company/about/bg_one.jpg',
                    title: this.$i18n.t('about.about.banner.title'),
                    enTitle: '',
                },
                about: this.$i18n.t('about.about'),
                development: this.$i18n.t('about.development'),
                service: this.$i18n.t('about.service'),
                product: this.$i18n.t('about.product'),
            }
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "关于我们"
            document.getElementById("bodyId").appendChild(div);
//            setTimeout(() => {
//                this.$nextTick(() => {
//                    this.$refs.innerBox.style.height = (this.$refs.innerImg.clientHeight - 110) + 'px'
//                })
//            }, 500)
        }
    }
</script>

<style lang="scss" scoped>
    section {
        padding-bottom: 80px;
        .about-title {
            color: rgba(159, 159, 159, 0.10);
            font-size: 80px;
        }
    }

    .item-box {
        .inner {
            width: 600px;
            height: 380px;
            background-color: $primary-color;
            position: relative;
            margin-top: -120px;
            .item-title {
                font-size: 24px;
            }
            .desc {
                /*font-size: 18px;*/
            }
        }
        .item0 {
            border-top-left-radius: 20px;
            margin-right: 20px;
        }
        .item1 {
            border-bottom-right-radius: 20px;
        }

    }

    .service-box {
        background-color: white;
        position: relative;
        margin-bottom: 81px;
        .inner-box {
            height: 577px;
        }
        .inner {
            position: absolute;
            top: 0;
            width: 100%;
            height: 700px;
            .service-img {
                height: 100%;
                overflow: hidden;
            }
        }

    }

    .team-box {
        .item-name {
            font-size: 30px;
            position: relative;
            &:before {
                content: "";
                width: 10%;
                height: 2px;
                background: $primary-color;
                position: absolute;
                bottom: -10px;
                left: 0;
                right: auto;
            }
        }
        .item-position {
            font-size: 20px;
        }
    }

    @media (max-width: $screen-md) {
        section {
            padding-bottom: 50px;
        }
        .item-box {
            .inner {
                width: 94%;
                height: auto !important;
                margin: 0 auto;
                .item-title {
                    font-size: 15px;
                }
                .desc {
                    /*font-size: 10px;*/
                }
                .service-img {
                    height: auto;
                }
            }
            .item1 {
                margin-top: 10px;
            }
        }
        .service-box {
            position: inherit;
            margin-bottom: 0;
            .inner {
                position: inherit;
            }
        }
        .team-box {
            .item-name {
                font-size: 18px;
                &:before {
                    bottom: -8px;
                }
            }
            .item-position {
                font-size: 14px;
            }
        }
    }

    @media (max-width: $screen-sm) {
        .item-box {
            .inner {
                .item-title {
                    font-size: 14px;
                }
            }
        }
        .service-box {
            .inner {
                height: auto;
            }
        }
    }

</style>
